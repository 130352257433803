.aviso_privacidad{
    padding: 6rem 0rem;
}
.aviso_privacidad label{
    justify-content: flex-end;
    display: flex;
    margin-top: 3rem;
}
.aviso_privacidad a{
    color: #2874A6;
    text-decoration: none;
}