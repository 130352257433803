.blog{
    width: 100%;
    padding: 6rem 0rem;
}
.blog-container{
    display: flex;
    flex-wrap: wrap;
}
.blog-content{
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 3px;
    box-sizing: border-box;
    margin: 0px 5px 40px 5px;
    width: calc(25% - 10px);
}
.blog-content a{    
    text-decoration: none;
}
.blog-content h2{
    font-size: 17px;
    color: #2E5771;
    font-weight: 500;
    margin: 1rem .5rem;
}
.blog-content img{
    width: 100%;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.blog-content-text{
    padding: .5rem;
    border-top: 1px solid #d7d7d7;
    min-height: 100px;
    height: auto;
}
.blog-fecha{
    color: #545454;;
    border-top: 1px solid #d7d7d7;
}
.blog-fecha label{
    font-size: 12px;
    margin-left: 10px;
}

.blog-content:hover{
    cursor: pointer;
    box-shadow: 0 8px 16px 0 #0003;
}

.blog-box {
    width: 100%;
    height: auto;
    min-height: 135px;
    align-content: space-between;
    display: grid;
}

@media (max-width:768px){
    .blog-content{
        width: calc(50% - 10px);
    }
}

@media (max-width:480px){
    .blog-content{
        width: calc(100% - 10px);
    }
}