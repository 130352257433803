.categorias{
    padding: 20px;
    width: 100%;
    margin: 40px auto;
}
.categorias ul{
    display: flex;
    margin-bottom: 10px;
}
.categorias ul li{
    list-style: none;
    background: #eee;
    padding: 8px 20px;
    margin: 5px;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 4px;
}
.categorias ul li.active{
    /*background: #03a9f4;*/
    background:#A44F71;
    color: #fff;
}

.product{
    display: flex;
    flex-wrap: wrap;
}
.product .itemBox{
    position: relative;
    width: 300px;
    height: 300px;
    margin: 5px;
}
.product .itemBox img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}