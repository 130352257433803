@import url('https://cdn-uicons.flaticon.com/2.0.0/uicons-regular-straight/css/uicons-regular-straight.css');
.cotizador{
    width: 100%;
    display: flex;
    margin-top: 3rem
}
.list_services{
    width: 60%;
}

.item_service{
    /*max-width: 500px;*/
    padding: 12px;
    margin: 0px 24px 24px 0px;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    padding: 1.5rem;
    margin: 0px 120px 24px 0px;
}
.box_item_servce h4{
    font-weight: 500;
    font-size: 18px;
}
.box_item_servce p{
    font-size: 15px;
    color: #545454;
    letter-spacing: 0;
    line-height: 24px;
}
.box_item_servce button{

    padding: 8px 20px;
    background: #2874A6;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    margin-top: 2rem;
    border-radius: 3px;
    border: 1px solid #2874A6;
    align-items: center;
    display: flex;
    
}
.box_item_servce button i{
    display: flex;
    margin-left: 5px;
}



.box_message{
    width: 100%;
    display: grid;
    margin-bottom: 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
}
.box_message_title{
    background-color: #f1f1f1;
}
.box_message_title h2{
    font-size: 20px;
    color: #2874A6;
    padding: 1.5rem;
    padding: 1rem;
    margin-bottom: 0;
}
.box_message_text p{
    padding: 1rem;
    margin-bottom: 1rem;
}

.message_alert{
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #BEE5EB;
    border-radius: 3px;
    background-color: #D1ECF1;
    padding: 1rem;
    display: flex;
}
.message_alert p{
    font-size: 13px;
    margin-bottom: 0rem;
}
.message_alert button{
    border: none;
    background-color: transparent;
    align-items: flex-start;
    display: flex;
    color: #545454;
    margin-top: 2px;
}

.list_result{
    width: 40%;
}
.box_list_result{
    width: 100%;
    display: grid;
    margin-bottom: 1rem;
    border: 1px solid #dbdbdb;
    border-radius: 3px;
}
.box_result_title{
    background-color: #f2f2f2;
}
.box_result_title h2{
    font-size: 20px;
    color: #2874A6;
    padding: 1.5rem;
    padding: 1rem;
    margin-bottom: 0;
}

.box_addedservice{
    padding: 1rem;
}
.box_addedservice ul{
    padding-left: 0px;
}
.box_addedservice ul li{
    list-style: none;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.item_added_service{
    align-items: center;
    display: flex;
}
.box_addedservice button{
    padding: 8px 20px;
    background: #2874A6;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    border-radius: 3px;
    border: 1px solid #2874A6;
}
.box_addedservice button:hover{
    border: 1px solid #2874A6;
    background: #fff;
    color: #2874A6;
    border-radius: 3px;
}
.box_item_servce button:hover{
    border: 1px solid #2874A6;
    background: #fff;
    color: #2874A6;
    border-radius: 3px;
}
.enviar_cotizacion button:disabled {
    background-color: #7A92A2;
    border: #7A92A2;
    color: #FFF;
}
.checkbox_cotizador label{
    display: block;
}
.checkbox_cotizador input[type=checkbox] {
    margin-bottom: 0px;
    margin-right: 5px;
}
.box_item_servce button:disabled{
    background-color: #7A92A2;
    border: #7A92A2;
    color: #FFF;
}

/* Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    width: 500px;
    height: 300px;
    max-height: 350px;
    justify-content: center;
    display: flex;
    position: fixed;
    top: 47%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 25px;
  }
  .modal-contact h2{
    font-weight: 500;
    font-size: 18px;
    margin-top: 2rem;
  }
  .form_modal_contact {
    margin-top: 2rem;
}
  .modal-options {
    display: flex;
    justify-content: center;
}
.modal-option label{
    width: 100%;
    cursor: pointer;
}  
.modal-option button {
    min-width: 100px;
    border: 1px solid #2874A6;
    background: transparent;
    margin: 5px;
    cursor: pointer;
    border-radius: 3px;
    padding: 2px;
}
.modal-option  button:hover{
    background: #C9DCE9;
    color: #2874a6;;
}
.form_modal_contact input[type=text], .form_modal_contact input[type=email] {
    width: 100%;
    height: 42px;
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    margin-bottom: 1.5rem;
    border-right: none;
}
.send_cotizacion{
    display: flex;
}
.btn_enviar_cotizacion {
    padding: 6px 20px;
    background: #2874A6;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    margin-top: 0rem;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 42px;
    margin-left: -3px;
}
.btn_enviar_cotizacion:hover {
    border: 1px solid #2874A6;
    background: #fff;
    color: #2874A6;
    border-radius: 3px;
    cursor: pointer;
}



@media (max-width: 768px) {
    .cotizador{
        display: block;
    }
    .list_services{
        width: 100%;
    }
    .item_service{
        width: 100%;
    }
    .list_result{
        width: 100%;
    }
    .modal{
        width: 90%;
    }
}