

.menucarga {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    transition: background-color 0.3s ease;
    z-index: 1;
    height: 75px;
    align-items: center;
    display: flex;
    -webkit-box-shadow: 10px 4px 12px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 10px 4px 12px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 10px 4px 12px 0px rgba(0,0,0,0.4);
}
.logo_carga a{
    text-decoration: none;
}
.menu_responsive_carga{
    display: none;
}

.nav-menu-carga{
    display: flex;
    justify-content: space-between;
}
.menu_img_show_carga {    
    font-size: 2rem;
    color: #2874A6;
    font-weight: bold;
}
.menucarga ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    display: flex;
}
.menucarga ul li {
    display: inline-block;
    margin: 0 5px;
    padding: 4px 7px;
    border-radius: 3px;
}
.menucarga ul li .submenu_carga{
    display: none;
  }
.menucarga ul li:hover .submenu_carga{
    display: grid;
    padding: 5px 10px;
    border: 1px solid #f1f1f1;
}
.submenu_carga{
    background: #fff;
    min-width: auto;
    height: auto;
    position: absolute;
    -webkit-box-shadow: 10px 10px 14px 0px rgba(0,0,0,0.17);
    -moz-box-shadow: 10px 10px 14px 0px rgba(0, 0, 0, 0.17);
    box-shadow: 10px 10px 14px 0px rgba(0,0,0,0.17);
}
.submenu_carga li{
    width: 100%;
}
.submenu_carga li a{
    margin: 5px 0px;
}
.menucarga.scrolled .menu_img_show_carga {

    width: 100px;
    height: 60px;
    background-position: center center;
    background-size: 100%;
    background-repeat: no-repeat;
}
.menucarga.scrolled ul li:hover .submenu_carga{
    background-color: #2874A6;
    border: 1px solid #2874A6;
}

.menucarga ul li a {
    font-size: 14px;
    color: #2874A6; 
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.menucarga ul ul li a {
    font-size: 12px;
    color: #2874A6; 
    text-decoration: none;
    display: flex;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
}
.menucarga ul li a i{
    display: flex;
}
.menucarga.scrolled ul li a {
    color: #fff; 
}
.menucarga ul li a:hover {
    color: #949494;
}
.menu_medio,
.menu_derecho{
    display: flex;
    align-items: center;
}
.footer_carga ul li a{
    text-decoration: none;
}

.menu_carga_activo{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #2874A6;
    transition: background-color 0.3s ease;
    z-index: 1;

}
.submenu_list_carga{
    margin-top: 2rem;
}
.submenu_list_carga ul{
    margin-left: 0px;
    padding-left: 0px;
}
.submenu_list_carga ul li{
    border-bottom: 1px solid #c1c1c166;
    margin: 1rem 0rem;
    list-style: none;
}
.submenu_list_carga ul li a{
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    display: flex;
    justify-content: space-between;
}

ul.submenu_item{
    background: #2874A6;
    padding: 5px 0px;
}
ul.submenu_item li {
    color: #fff;
    border-bottom: none; 
    margin: 0.5rem 1rem!important;
    list-style: none;
}

@media (max-width: 768px) {
    .menu_img_show_carga {
        font-size: 1.5rem;
        color: #2874A6;
        font-weight: 600;
    }
    .menucarga{
        display: none;       
    }
    .menu_responsive_carga{
        display: block;
        background: #fff;
        height: 70px;
        align-items: center;
        display: flex;
    }
    .menu_responsive_carga_content a{
        text-decoration: none;
    }
    .menu_responsive_carga_content{
        width: 100%;
        justify-content: space-between;
        align-items: center;
        display: flex;
        height: 60px;
        padding: 0rem 0.5rem;
    }
    .servicio_transporte .slider-destino{
        margin-top: 0px;
    }
    .btn_menu_responsive_carga button{
        background: transparent;
        border: none;
        align-items: center;
        display: flex;
        color: #2874A6;
    }
    .btn_menu_responsive_carga button i{
        display: flex;
        font-size: 20px;
    }
    .text_servicio_transporte h2{ 
        font-size: 24px;
        letter-spacing: 0;
        line-height: 40px;
        font-weight: 400;
    }
    .text_servicio_transporte p{
        font-weight: 400;
        font-size: 15px;
    }
    .title_soluciones h2{
        font-size: 1.5rem;
    }
    .title_soluciones p{
        font-size: 1rem;
        font-weight: 400;
        padding: 0px 20px;
    }
    .menu_carga_activo .menu_img_show_carga{
        color: #fff;
    }
    .menu_carga_activo button{
        color: #fff;
    }

  }