.blog-nota{
    width: 100%;
    padding: 4rem 0rem;
}
.banner-blog{
    width: 100%;
    height: 100%;
    min-height: 375px;
    background-image: url('../assets/images/blog/Tendencias.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 3px;
}
.banner-info {
    margin-top: 4rem;
}
.banner-info h1{
    font-size: 20px;
    font-weight: 500;
}
.banner-info h3{
    font-size: 18px;
    font-weight: 500;
}
.banner-info p{
    font-size: 16px;
    font-weight: 400;
}