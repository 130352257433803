.contact{
	width: 100%;
	display: flex;
    margin-top: 4rem;
}
.contact_info{
	width: 50%;
	display: grid;
    padding: 15px;
}
.contact_info img{
    width: 55%;
}
.contact_info h2, .formulario h2{
	font-size: 22px;
	color: #2874A6;
}
.contact_info p{
	/*font-size: 22px;*/
	color: #545454;
    margin-right: 10px;
}
.call_phone, .call_mail{
	display: flex;
}
.social{
    display: flex;
}
.social_icon_contac{
    margin-top: 1rem;
    margin-bottom: .5rem;
    padding: 7px;
    border-radius: 25px;
    border: 1px solid #2874A6;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 10px;
}
.social_icon_contac a{
    text-decoration: none;
}
.social_icon_contac:hover {
    background: #DFF3FD;
    cursor: pointer;
}
.social_icon_contac i, .call_phone i, .call_mail i{
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    color: #2874A6;
}

.formulario{
	width: 50%;
}
.formulario form{
	display: grid;
}
.form-group{
	width: 100%;
    display: flex;
    justify-content: space-between;
}
input[type=text]{
    width: 46%;
    height: 50px;
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    margin-bottom: 1.5rem;
}
input[type=email]{
    width: 100%;
    height: 50px;
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    margin-bottom: 1.5rem;
}
select{
    width: 46%;
    height: 50px;
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    margin-bottom: 1.5rem;
}
textarea{
    width: 100%;
    min-height: 70px;
    padding: 4px 10px;
    border-radius: 4px;
    border: 1px solid #dbdbdb;
    margin-bottom: 1.5rem;
}
input[type=checkbox]{
	border: 1px solid #dbdbdb;
	margin-bottom: 2rem;
}
.btn_enviar {
    padding: 10px 32px;
    background: #2874A6;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    margin-top: 0rem;
    border-radius: 3px;
    border: none;
}
.btn_enviar:hover {
    border: 1px solid #2874A6;
    background: #fff;
    color: #2874A6;
    border-radius: 3px;
}

.alertMessage{
    background:#C9EDF2;
    border: 1px solid #B1E6EC;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    display: block;
    margin-top: 1rem;
}
.alertMessage label{
    font-weight: 500;
    color:#062c33;
}
.alertMessage p{
    color:#0c5460;
    margin-bottom: 0px;
}
@media (max-width: 768px) {
    .contact{
        display: block;
    }
    .contact_info{
        width: 100%;
    }
    .formulario{
        width: 100%;
        padding: 15px;
    }
    .form-group{
        display: block;;
    }
    input[type=text],  input[type=email]{
        width: 100%;
        margin-bottom: 0.8rem;
    }
    textarea{
        margin-bottom: 0.8rem; 
    }
    select{
        width: 100%;
        margin-bottom: 0.5rem; 
    }
    input[type=checkbox] {
        margin-bottom: 0.5rem;
    }
    .term-cond{
        margin-bottom: 0.8rem; 
        padding: 0px 10px;
    }
}